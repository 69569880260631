
import { defineComponent, ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'

export default defineComponent({
  setup () {
    const tableRef = ref<HTMLElement>()
    const tabulator = ref()
    const filter = reactive({
      field: 'name',
      type: 'like',
      value: ''
    })

    const initTabulator: any = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: 'https://dummy-data.left4code.com',
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'PRODUCT NAME',
            minWidth: 200,
            responsive: 0,
            field: 'name',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter (cell: any) {
              return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().name
                }</div>
                <div class="text-gray-600 text-xs whitespace-nowrap">${
                  cell.getData().category
                }</div>
              </div>`
            }
          },
          {
            title: 'IMAGES',
            minWidth: 200,
            field: 'images',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter (cell: any) {
              return `<div class="flex lg:justify-center">
                  <div class="intro-x w-10 h-10 image-fit">
                    <img alt="Midone Tailwind HTML Admin Template" class="rounded-full" src="${require('@/midone/assets/images/' +
                      cell.getData().images[0])}">
                  </div>
                  <div class="intro-x w-10 h-10 image-fit -ml-5">
                    <img alt="Midone Tailwind HTML Admin Template" class="rounded-full" src="${require('@/midone/assets/images/' +
                      cell.getData().images[1])}">
                  </div>
                  <div class="intro-x w-10 h-10 image-fit -ml-5">
                    <img alt="Midone Tailwind HTML Admin Template" class="rounded-full" src="${require('@/midone/assets/images/' +
                      cell.getData().images[2])}">
                  </div>
              </div>`
            }
          },
          {
            title: 'REMAINING STOCK',
            minWidth: 200,
            field: 'remaining_stock',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'STATUS',
            minWidth: 200,
            field: 'status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter (cell: any) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().status ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().status ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: 'ACTIONS',
            minWidth: 200,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter (cell: any) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>
                <a class="flex items-center text-theme-6" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i> Delete
                </a>
              </div>`)
              cash(a).on('click', function () {
                console.log(cell)
              })

              return a[0]
            }
          },

          // For print format
          {
            title: 'PRODUCT NAME',
            field: 'name',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'CATEGORY',
            field: 'category',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'REMAINING STOCK',
            field: 'remaining_stock',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'STATUS',
            field: 'status',
            visible: false,
            print: true,
            download: true,
            formatterPrint (cell: any) {
              return cell.getValue() ? 'Active' : 'Inactive'
            }
          },
          {
            title: 'IMAGE 1',
            field: 'images',
            visible: false,
            print: true,
            download: true,
            formatterPrint (cell: any) {
              return cell.getValue()[0]
            }
          },
          {
            title: 'IMAGE 2',
            field: 'images',
            visible: false,
            print: true,
            download: true,
            formatterPrint (cell: any) {
              return cell.getValue()[1]
            }
          },
          {
            title: 'IMAGE 3',
            field: 'images',
            visible: false,
            print: true,
            download: true,
            formatterPrint (cell: any) {
              return cell.getValue()[2]
            }
          }
        ],
        renderComplete () {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win: any = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })

    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint
    }
  }
})
